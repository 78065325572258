import React, { useEffect, useState } from 'react';
import TopNavbar from '../../components/topNavbar';
import { Grid, IconButton, Paper, TextField, Typography, makeStyles } from '@material-ui/core';
import dummyWhite from "../../assets/images/logos/user.png"
import { ArrowForwardIos, Edit, EditOutlined, Instagram } from '@material-ui/icons';
import { colors } from '../../assets/css/colors';
import { useSelector } from 'react-redux';
import {useHistory} from "react-router-dom"
import flasIcon from "../../assets/images/lightning.png";


const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      // textAlign: 'center',
    //   color: "black",
      background: "white",
      position: "relative",
      borderRadius: "5px",
      marginBottom: "4px",
    //   height: '100vh'
    },
    instaLogin: {
        backgroundColor: "#D6EAF8",
        border: `2px solid ${colors.primary}`,
        width: "auto",
        height: "auto",
        padding: "10px",
        borderRadius:    "10px"
    },
}))


function ProfileScreen({ isMobile }) {

    const classes = useStyles();
    const history = useHistory()

    const userData = useSelector(state => state?.auth?.user)

    const [localState, setLocalState] = useState({
        "name": userData?.username
    })

    useEffect(() => {
        setLocalState({
            name: userData?.username,
        })
    }, [userData])

    const handleInstaCTA = () => {
        if(userData?.instaHandle === "none"){
            window.open(`https://www.facebook.com/v19.0/dialog/oauth?client_id=316919167961476&display=page&extras=%7B%22setup%22%3A%7B%22channel%22%3A%22IG_API_ONBOARDING%22%7D%7D&redirect_uri=https%3A%2F%2Fcreator.creatorcheckout.com%2Finstagram-handle&response_type=token&scope=instagram_basic%2Cpages_show_list%2Cbusiness_management%2Cinstagram_manage_comments%2Cinstagram_manage_messages%2Cinstagram_manage_insights&state=${userData?.phoneNo}-app3`)
        } else {
            history.push("/main/insta-handle")
        }
    }

    return (
        <React.Fragment>
            <TopNavbar isMobile={isMobile} screen={"home"} backButton={true}/>
            <Grid container style={{marginTop: "20%"}}>
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Paper elevation={1} style={{height: "80px", width: "80px", borderRadius: "50px", position: "relative", padding: 5}}>
                                    <img src={dummyWhite} style={{height: "80px", width: "80px", borderRadius: "50px"}} />
                                    <IconButton style={{background: colors.primary, padding: 5, position: "absolute", bottom: 0, right: 0}}>
                                        <EditOutlined style={{color: "white", fontSize: "18px"}} />
                                    </IconButton>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}>
                                <TextField label="Name" variant='outlined' fullWidth size='medium' inputProps={{
                                    style: {fontSize: "14px"}
                                }}
                                value={localState?.name}
                                InputLabelProps={{style: {"fontSize": "14px"}}}
                                InputProps={{style: {borderRadius: "10px"}}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="About me" placeholder='Add bio to your shop' variant='outlined' fullWidth size='medium' inputProps={{
                                    style: {fontSize: "14px"}
                                }}
                                InputLabelProps={{style: {"fontSize": "14px"}}}
                                InputProps={{style: {borderRadius: "10px"}}}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className={classes.instaLogin}>
                                    <Grid container spacing={1} alignItems="center" style={{cursor: "pointer"}} onClick={() => handleInstaCTA()}>
                                        <Grid item xs={11} container spacing={1}>
                                            <Grid item xs={12} style={{display: "flex", alignItems: 'center'}}>
                                                <Instagram style={{fontSize: "16px", marginRight: "5px"}}/>
                                                <Typography style={{fontSize: "14px", marginTop: "1px"}}>
                                                    Social Login
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography style={{fontSize: "14px", opacity: 0.7}}>
                                                    Connect your Instagram for a personalized experience.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xx={1}>
                                            <ArrowForwardIos style={{fontSize: "16px"}} />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{fontSize: "16px", fontWeight: "500"}}>
                                    Additional Info
                                </Typography>
                                <Typography style={{fontSize: "12px", color: "grey"}}>
                                    Private to you!
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={12}></Grid> */}
                            <Grid item xs={12}>
                                <TextField label="Gender" variant='outlined' fullWidth size='medium' inputProps={{
                                    style: {fontSize: "14px"}
                                }}
                                // value={localState?.name}
                                InputLabelProps={{style: {"fontSize": "14px"}}}
                                InputProps={{style: {borderRadius: "10px"}}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Date of Birth" placeholder='For eg. 18/10/2001' variant='outlined' fullWidth size='medium' inputProps={{
                                    style: {fontSize: "14px"}
                                }}
                                InputLabelProps={{style: {"fontSize": "14px"}}}
                                InputProps={{style: {borderRadius: "10px"}}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Email" variant='outlined' fullWidth size='medium' inputProps={{
                                    style: {fontSize: "14px"}
                                }}
                                InputLabelProps={{style: {"fontSize": "14px"}}}
                                InputProps={{style: {borderRadius: "10px"}}}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{fontSize: "16px", fontWeight: "500"}}>
                                    Account Details
                                </Typography>
                                <Typography style={{fontSize: "12px", color: "grey"}}>
                                    Payouts will be done in the first week of every month in the following account
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField disabled placeholder='Add your bank details here' variant='outlined' fullWidth size='medium' inputProps={{
                                    style: {fontSize: "14px", fontStyle: "italic", cursor: "pointer"}
                                }}
                                InputLabelProps={{style: {"fontSize": "14px"}}}
                                InputProps={{style: {borderRadius: "10px", cursor: "pointer"}, endAdornment: (<ArrowForwardIos style={{color: "black", fontSize: "18px"}}/>)}}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={0}>
                        <Grid container spacing={1} justifyContent="center" style={{height: isMobile ? "auto" : "120px"}}>
                            <Grid item>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <img src={flasIcon} style={{height: "15px"}}/>
                                    <Typography variant="caption">
                                    Powered by 
                                    </Typography>
                                    <span style={{
                                        // color: "#4D63DD",
                                        background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent', 
                                        fontWeight: "bold",
                                        marginLeft: 3,
                                        fontSize: "13px"
                                        }}>
                                        <i>Paperplane</i></span>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            
        </React.Fragment>
    )
}


export default ProfileScreen;