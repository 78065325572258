import { Button, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import GenericBottomDrawer from "../../components/GenericBottomDrawer";
import { colors } from "../../assets/css/colors";
import { ArrowForward, LockOutlined, Replay } from "@material-ui/icons";
import {useHistory} from "react-router-dom"
import customAxios from "../../axios";
import { useDispatch } from "react-redux";
import { ADD_PROFILE_DATA } from "../../redux/constants";

import cLogo from "../../assets/images/logos/download.png"

import frontAsset from "../../assets/animationData/Website (2).gif"


function AuthScreen({isMobile}) {


  const [loader, setLoader] = useState(false)
  const [mobile, setMobile] = useState('');

  const [otpErr, setOtpErr] = useState(false);

  const history  = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    let token = localStorage.getItem("token");

    if(token) {
      customAxios.get("creator/fetch-profile").then((res) => {
        if(res?.data?.success){
            history.push("/main/home");
            dispatch({type: ADD_PROFILE_DATA, payload: res?.data?.data})
        }
      }).catch(err => {
        console.log(err);
      }) 
    } 
  }, [])

  const [contentType, setContentType] = useState('get-number');

    const handleNumberInput = () => {
        setLoader(true)
        if(mobile?.length === 10){
            customAxios.get(`creator/send-otp?mobile_no=${mobile}`).then((res) => {
                if(res?.data?.success){
                    setLoader(false)
                    setContentType("get-otp");
                }
            }).catch((err) => {
                console.log(err);
            })
        } 
    }

    const handleOTPInput = () => {

        setLoader(true)
        setOtpErr(false)

        let errCheck = false
        let otpString = ''
        otp.map(v => {
            if(v === '' || !v){
                errCheck = true;
            } else {
                otpString += v
            }
        })

        if(!errCheck){
            customAxios.put(`auth/validate-otp`, {
                phoneNo: mobile,
                otp: otpString
            }).then((res) => {
                if(res?.data?.success) {
                    customAxios.put("creator/check-mobile", {
                        phoneNo: mobile,
                        otp: otpString
                    }).then((res1) => {
                        if(res1?.data?.success){
                            setLoader(false);
                            localStorage.setItem("token", res1?.data?.data?.accessToken)
                            dispatch({"type": ADD_PROFILE_DATA, payload: res1?.data?.data?.creator})
                            history.push("/main/home")
                        } else {
                            if(res1?.data?.message === "Creator Not Exists") {
                                // this neeeds to be change later
                                customAxios.post('creator/create', {
                                    phoneNo: mobile,
                                    username: `+91${mobile}`,
                                    instaHandle: "none"
                                }).then(res2 => {
                                    if(res2?.data?.success) {
                                        setLoader(false);
                                        localStorage.setItem("token", res2?.data?.data?.accessToken)
                                        dispatch({"type": ADD_PROFILE_DATA, payload: res2?.data?.data?.creator})
                                        history.push("/main/home")
                                    }
                                }).catch(err2 => console.log(err2))
                            } else {
                                setLoader(false)
                            }
                        }
                    }).catch(err1 => console.log(err1))
                } else {
                    setLoader(false)
                    setOtpErr(true)
                }
            }).catch((err) => {
                console.log(err);
            })
            // history.push("/main/home")
        } else {
            setLoader(false)
        }
    }

    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const otpRef = useRef([])

  const handleInputChange = (index) => (event) => {

    const value = event.target.value;

    // Perform a regex check for numbers only
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value ? value[0] : value;
      setOtp(newOtp);

      // Move focus to the next input field
      if (value !== '' && otpRef.current[index+1]) {
        otpRef.current[index+1].focus();
      }
    }
  };

    const getContent = () => {
        switch(contentType) {
            case "get-number": {
                return (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography style={{fontWeight: "bold", fontSize: "14px"}}>
                                Hello Creator, Welcome to your Creator Checkout account
                            </Typography>
                            {/* <Typography style={{color: "red", fontSize: "14px", marginTop: "20px", overflowX: "hidden"}}>
                                For Facebook review, use this document - <a style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => window.open("https://docs.google.com/document/d/1KKM8uGX66RhyTvRf4yN3fmlrptVAXOdeUfDdZgZL67Q/edit?usp=drive_link", null)}>
                                https://docs.google.com/document/d/1KKM8uGX66RhyTvRf4yN3fmlrptVAXOdeUfDdZgZL67Q/edit?usp=drive_link
                                    </a>
                            </Typography>
                            <Typography style={{color: "red", fontSize: "14px", marginTop: "20px"}}>
                                For Facebook review login, use this phone number - <b>8171017538</b>
                            </Typography> */}
                            <Typography style={{fontSize: "10px", color: "grey", marginTop: "10px"}}>
                                Please enter your mobile number to continue
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="standard" size='small' value={mobile} 
                                inputProps={{style: {fontSize: "12px"}, maxLength: 10}}
                                onChange={(e) => {
                                    setMobile(e?.target?.value)
                                }} placeholder='Enter 10 digit number' />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant='contained' fullWidth style={{borderRadius: "20px", background: colors.primaryGradient, color: "white"}} disableElevation onClick={() => {
                                handleNumberInput()
                            }}
                            startIcon={<LockOutlined />}
                            endIcon={loader ? <CircularProgress style={{color: "white", height: 18, width: 18}} /> : null}
                            >
                                Proceed Securely
                            </Button>
                        </Grid>
                    </React.Fragment>
                )
            }

            case "get-otp": {
                return (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography style={{fontWeight: "bold", fontSize: "14px"}}>
                                Verify your mobile number
                            </Typography>
                            {/* <Typography style={{color: "red", fontSize: "14px", marginTop: "20px", overflowX: "hidden"}}>
                                For Facebook review, use this document - <a style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => window.open("https://docs.google.com/document/d/1KKM8uGX66RhyTvRf4yN3fmlrptVAXOdeUfDdZgZL67Q/edit?usp=drive_link", null)}>
                                https://docs.google.com/document/d/1KKM8uGX66RhyTvRf4yN3fmlrptVAXOdeUfDdZgZL67Q/edit?usp=drive_link
                                    </a>
                            </Typography>
                            <Typography style={{color: "red", marginTop: "20px", fontSize: "14px"}}>
                                For Facebook review login, use this OTP - <b>404040</b>
                            </Typography> */}
                            <Typography style={{fontSize: "10px", color: "grey", marginTop: "10px"}}>
                                Please enter 6-digit OTP sent to you at <span>+91-{mobile}</span>
                                <span>
                                    <Button variant={"text"} color="primary" onClick={() => handleNumberInput()} style={{fontSize: "12px", marginLeft: "5px"}}>
                                    <Replay style={{fontSize: "16px", marginRight: "5px"}}/> Resend
                                    </Button>
                                </span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {/* {[0, 1, 2, 3, 4, 5].map(v => (
                                <TextField variant="outlined" size='small' value={mobile} style={{width: "12%", marginRight: "10px"}}
                                    inputProps={{style: {fontSize: "12px"}}}
                                    onChange={(e) => {
                                        setMobile(e?.target?.value)
                                    }} placeholder='Enter 10 digit number' />
                            ))} */}
                            {otp.map((value, index) => (
                                <TextField
                                key={index}
                                variant="outlined"
                                size="small"
                                margin="normal"
                                error={otpErr}
                                // focused={index===0}
                                autoFocus={index===0}
                                style={{width: "12%", marginRight: "10px"}}
                                inputRef={(ref) => otpRef.current[index] = ref}
                                type={isMobile ? "number": "text"}
                                value={value}
                                onChange={handleInputChange(index)}
                                inputProps={{
                                    maxLength: 1,
                                    style: { textAlign: 'center' },
                                }}
                                />
                            ))}
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant='contained' fullWidth style={{borderRadius: "20px", background: colors.primaryGradient, color: "white"}} disableElevation onClick={() => {
                                handleOTPInput()
                            }}
                            // startIcon={<LockOutlined />}
                            endIcon={loader ? <CircularProgress style={{color: "white", height: 18, width: 18}} /> : <ArrowForward />}
                            >
                                Verify OTP
                            </Button>
                        </Grid>
                    </React.Fragment>
                )
            }
        }
    }


    return (
        <React.Fragment>
            <Grid container style={{background: "whitesmoke", height:"100%"}}>
                <Grid item xs={12} container justifyContent="center">
                    <Grid item style={{marginTop: "5%", textAlign: 'center'}}>
                        <img src={cLogo} />
                        <Typography>
                            Creator Checkout
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={12}></Grid> */}
                    

                    <Grid item style={{marginTop: "5%", textAlign: "center"}}>
                        <img src={frontAsset} width={"80%"} />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <GenericBottomDrawer open={true} setOpen={null} isMobile={isMobile} drawerContent={
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>
                                    Creator Checkout
                                </Typography>
                            </Grid>
                            {getContent()}
                        </Grid>
                    } />
                </Grid>
            </Grid>
        </React.Fragment>
    )

}

export default AuthScreen;