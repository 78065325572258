import { AppBar, Avatar, CircularProgress, Divider, Drawer, Grid, IconButton, Paper, SwipeableDrawer, Typography, makeStyles } from "@material-ui/core";
import { ArrowBack, ArrowForwardIos, ExitToApp, LinkOff, Menu } from "@material-ui/icons";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {useHistory} from "react-router-dom"

import userIcon from "../../assets/images/logos/user.png"
import customAxios from "../../axios";
import { ADD_PROFILE_DATA } from "../../redux/constants";


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "fixed",
        top: 0,
        zIndex: 2,
        minHeight: "5%",
        maxHeight: "5%"
    },
    drawerLayout: {
        borderRadius: "0px 60px 60px 0px"
    }
}))


function TopNavbar({ isMobile, backButton = false, navBarText = null }) {

    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()

    const userData = useSelector(state => state?.auth?.user)

    const [openDrawer, setOpenDrawer] = useState(false)
    const [unlinkLoader, setUnlinkLoader] = useState(false)

    const unlinkInstaAccount = () => {
        setUnlinkLoader(true)
        customAxios.get("creator/unlink-insta").then(res => {
            if(res?.data?.success){
                setUnlinkLoader(false)
                setOpenDrawer(false)
                let token = localStorage.getItem("token");

                if(token) {
                    customAxios.get("creator/fetch-profile").then((res) => {
                        if(res?.data?.success){
                            // history.push("/main/home");
                            dispatch({type: ADD_PROFILE_DATA, payload: res?.data?.data})
                        }
                    }).catch(err => {
                        console.log(err);
                    }) 
                } else {
                    history.push("/auth")
                }
            }
        })
    }


    const getDrawerContent = () => {

        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12} container justifyContent="center" style={{padding: "5%"}} spacing={1}>
                        <Grid item>
                            <Paper elevation={2} style={{borderRadius: "40px", height: "70px", width: "70px", marginTop: "15%"}}>
                                <Avatar src={userIcon} style={{height: "70px", width: "70px"}}/>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item>
                            <Typography style={{fontSize: "20px", fontWeight: "500"}}>
                                {userData?.username}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={1} style={{padding: "5%", cursor: "pointer"}} alignItems="center" onClick={() => {
                        history.push("/main/profile");
                    }}>
                        <Grid item>
                            <Typography style={{fontSize: "16px"}}>
                                Profile
                            </Typography>
                            <Typography style={{fontSize: "12px", color: "grey"}}>
                                Build your Creator Checkout profile
                            </Typography>
                        </Grid>
                        <Grid item style={{marginLeft: "auto"}}>
                            <ArrowForwardIos style={{fontSize: "18px"}} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{background: "rgba(0, 0, 0, 0.04)"}}/>
                    </Grid>
                    <Grid item xs={12} container spacing={1} style={{padding: "5%", cursor: "pointer"}} alignItems="center" onClick={() => {
                        localStorage.removeItem("token");
                        history.push("/auth")
                    }}>
                        <Grid item>
                            <Typography style={{fontSize: "16px"}}>
                                Logout
                            </Typography>
                        </Grid>
                        <Grid item style={{marginLeft: "auto"}}>
                            <ExitToApp style={{fontSize: "22px"}} />
                        </Grid>
                    </Grid>
                    {/* {(userData?.instaHandle !== "none") ? <React.Fragment>    
                            <Grid item xs={12}>
                                <Divider style={{background: "rgba(0, 0, 0, 0.04)"}}/>
                            </Grid>
                            <Grid item xs={12} container spacing={1} style={{padding: "5%", cursor: "pointer"}} alignItems="center" onClick={() => {
                                unlinkInstaAccount()
                            }}>
                                <Grid item>
                                    <Typography style={{fontSize: "16px", color: "red"}}>
                                        Unlink Instagram account
                                    </Typography>
                                </Grid>
                                <Grid item style={{marginLeft: "auto"}}>
                                    {unlinkLoader ? <CircularProgress size={"22px"} style={{fontSize: "22px", color: "red"}} /> : <LinkOff style={{fontSize: "22px", color: "red"}} />}
                                </Grid>
                            </Grid>
                        </React.Fragment> : null} */}
                </Grid>
            </React.Fragment>
        )
    }

  
    return (
        <Paper elevation={1} className={classes.appBar} style={{ width: isMobile ? "90%": "23%", padding: isMobile ? "5%" : "1%" }}>
            <Grid container spacing={1} alignItems='center' style={{display: "flex", alignItems: "center"}}>
                <Grid item>
                    <IconButton onClick={() => {
                        if(backButton) {
                            history.goBack()
                        } else {
                            setOpenDrawer(true)
                            console.log("")
                        }
                    }}>
                        {backButton ? <ArrowBack style={{height: "25px", width: "30px"}} /> : 
                        <Menu style={{height: "25px", width: "25px"}} />}
                    </IconButton>
                </Grid>
                {!navBarText ? <React.Fragment>    
                        <Grid item >
                            <Avatar src={userIcon} style={{height: "38px", width: "38px"}} />
                        </Grid>
                        <Grid item>
                            <Typography style={{fontSize: "15px", color: "grey"}}>
                                Hello
                            </Typography>
                            <Typography style={{fontSize: "15px"}}>
                                {userData?.username}
                            </Typography>
                        </Grid>
                    </React.Fragment> : <Grid item>
                            <Typography style={{fontSize: "16px"}}>
                                {navBarText}
                            </Typography>
                        </Grid>}
            </Grid>
            <SwipeableDrawer anchor={"left"} open={openDrawer} onClose={() => setOpenDrawer(false)}
                PaperProps={{className: classes.drawerLayout, style: {width: isMobile ? "80%" : "20%"}}}
            >
                {getDrawerContent()}
            </SwipeableDrawer>
        </Paper>  
    )
}


export default TopNavbar;